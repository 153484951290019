import React from 'react';
import { initPage } from 'aqlrc';
import { Link } from '../routes';
import Layout from '../components/Layout';
import { withI18next } from '../lib/withI18n';

class Error extends React.Component {
    static async getInitialProps(ctx) {
        const statusCode = ctx.res ? ctx.res.statusCode : ctx.err ? ctx.err.statusCode : null;

        return {
            statusCode
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            ...props,
        };
        this.cssHook = '';
        this.jsxHook = undefined;
    }


    render() {
        const { oCmsHeader, oCmsFooter, t } = this.props;
        return (
            <Layout header={oCmsHeader.content} footer={oCmsFooter.content}>
                <div className="intro intro--homepage" style={{ backgroundImage: 'url("/medias/homepage.png")', backgroundColor: '#00020e' }}>
                    <div className="intro__title">
                        <h1>404 {t('notFound')}</h1>
                        <p><button className="btn btn--form btn--red" type="button"><Link to="/"><a>{t('returnToHomepage')}</a></Link></button></p>
                    </div>
                </div>
            </Layout>
        );
    }
}

Error.defaultProps = {
    contentHtml : {
        content : '',
    },
    oCmsHeader : {
        content : '',
    },
    oCmsFooter : {
        content : '',
    },
    message : '404 Not found',
};

export default withI18next(['common'])(Error);
